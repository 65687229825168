<template>
    <Head title="Log in"/>

    <div class="reg_auth_row">
        <div class="logo_block">
            <a :href="$page.props.app.landing_url">
                <img src="/img/logo-auth.svg" alt="logo">
            </a>
        </div>
        <div>
            <Link :href="route('register')" class="auth_link register__link">
                Зарегистрироваться
            </Link>
        </div>
    </div>
    <div class="auth__wrapper">
        <div class="form__outer">
            <div class="auth__block">
                <div class="auth-block-inner">
                    <div class="form-title text-center">
                        <h6 class="size-16 ls-0_03em">Авторизация</h6>
                    </div>
                    <div v-if="status" class="info_block success_block">
                        <div class="info_block_icon"><span class="fm-check"></span></div>
                        <div class="info_block_content">
                            <div class="block_content_text">
                                {{ status }}
                            </div>
                        </div>
                    </div>
                    <form id="loginForm" @submit.prevent="submit" class="ajax-auth">
                        <BreezeValidationErrors class="mb-4"/>
                        <div class="form__group email__input input_block">
                            <label for="loginEmail" style="display:none;"></label>
                            <BreezeInput id="loginEmail" type="text" class='form-control'
                                         :class="{ form_error: (v$.form.email.$error || form.errors.email) && !form.statusData.pass_msg }" v-model="form.email"
                                         placeholder="Электронная почта"
                                         @keyup="clearError"
                                         autofocus
                                         autocomplete="username"/>
                            <p v-if="v$.form.email.$error" class="invalid-feedback">{{ v$.form.email.$errors[0].$message }}</p>
                            <p v-if="form.statusData.email_msg && !v$.form.email.$error" class="invalid-feedback">{{ form.statusData.email_msg }}</p>
                        </div>
                        <div class="form__group password__input input_block">
                            <label for="loginPassword" style="display:none;"></label>
                            <BreezeInput id="loginPassword" type="password" class="form-control"
                                         :class="{ form_error: v$.form.password.$error || form.statusData.pass_msg }" v-model="form.password"
                                         placeholder="Пароль" autocomplete="current-password"/>
                            <p v-if="v$.form.password.$error" class="invalid-feedback">{{ v$.form.password.$errors[0].$message }}</p>
                            <p v-if="form.statusData.pass_msg && !v$.form.password.$error" class="invalid-feedback">{{ form.statusData.pass_msg }}</p>

                        </div>
                        <div class="service__links">
                            <div>
                                <BreezeCheckbox name="remember" id="remainSystem" v-model:checked="form.remember"/>
                                <label class="input__checkbox-label" for="remainSystem">Оставаться в системе</label>
                            </div>
                            <div>
                                <Link :href="route('password.request')" class="forgot-link">
                                    Забыли пароль?
                                </Link>
                            </div>
                        </div>
                        <div class="login_button_outer">
                            <BreezeButton class="submit_button" :class="{ 'opacity-25': form.processing }"
                                          :disabled="form.processing">
                                Войти

                                <span class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </BreezeButton>
                        </div>
                        <div class="bottom_link">
                            <span>Еще нет аккаунта?</span>
                            <Link :href="route('register')">
                                Регистрация
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <BreezeFeedback/>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, email, helpers, minLength} from '@vuelidate/validators'
import BreezeButton from '@/Components/Button.vue'
import BreezeCheckbox from '@/Components/Checkbox.vue'
import BreezeGuestLayout from '@/Layouts/Guest.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeFeedback from '@/Components/Feedback.vue'
import BreezeValidationErrors from '@/Components/AuthErrors.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';

export default {
    setup: () => ({v$: useVuelidate()}),
    layout: BreezeGuestLayout,

    components: {
        BreezeButton,
        BreezeCheckbox,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        BreezeFeedback,
        Head,
        Link,
    },

    props: {
        canResetPassword: Boolean,
        status: Object,
    },
    computed: {
        errors() {
            return this.$page.props.errors
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                email: '',
                password: '',
                remember: false,
                recaptcha_token: null,
                statusData:[],
            })
        }
    },
    validations() {
        return {
            form: {
                email: {
                    required: helpers.withMessage('Поле обязательно для заполнения', required),
                    email: helpers.withMessage('Введите email верного формата', email)
                },
                password: {
                    required: helpers.withMessage('Поле обязательно для заполнения', required),
                },
            }
        }
    },

    mounted() {
        console.log(this);
    },
    methods: {
        clearError(){
            this.form.errors = [];
        },
        async submit() {
            let self = this;
            let $v = this.v$;
            const isFormCorrect = await this.v$.$validate()
            self.form.post(self.route('login'), {
                onFinish(response) {
                    self.form.reset('password')
                    $v.$reset()
                },
                onError(response){
                    self.form.statusData = response;
                }
            })
        }
    }
}
</script>
