<template>
    <Head :title="__('global.hint.pricing.title')"/>

    <Layout>
        <div class="dash__board__container">
            <div class="main_container">
                <div class="dashboard_panel">
                    <div class="dashboard_panel_inner">
                        <TableLoader v-if="!accountDataLoaded"/>

                        <template v-else>
                            <div class="resub-notice" v-if="!store.subscription.active">
                                Срок оплаченного пакета истек. Для продолжения использования сервиса просьба произвести
                                оплату.
                            </div>

                            <div class="trial-notice" v-if="store.subscription.trial">
                                Активирован бесплатный период пакета "Бизнес" до {{ trialDateEnd }}.
                            </div>

                            <div class="pricing__header">
                                <div class="pricing__header__period">
                                    <div class="input">
                                        <div class="input__inner">
                                            <input class="input__toggle" id="pricing-yearly" type="checkbox"
                                                   v-model="periodChecked">
                                            <label class="input__toggle-label input__toggle-label_switcher"
                                                   for="pricing-yearly">
                                                <span>Ежегодно <span class="pricing-tabs__btn__tag">-20%</span></span>
                                                <span class="input__toggle-label__switcher"></span>
                                                <span>Ежемесячно</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!hideSelector" class="pricing__header__currency">
                                    <div class="input _radioGroup">
                                        <div class="input__inner">
                                            <!-- Динамическое создание радио кнопок и меток -->
                                            <div
                                                v-for="currency in currencies"
                                                :key="currency.code"
                                                class="input__radioWrapper"
                                            >
                                                <input
                                                    class="input__radioInput"
                                                    type="radio"
                                                    name="currency"
                                                    :id="'c-' + currency.code"
                                                    :value="currency.code"
                                                    v-model="selectedCurrency"
                                                    @change="handleCurrencyChange"
                                                />
                                                <label
                                                    class="input__radioGroupLabel"
                                                    :for="'c-' + currency.code"
                                                >
                                                    <span>{{ currency.symbol }}</span>
                                                    <span>{{ currency.name }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="pricing">
                                <div class="pricing__left">
                                    <div class="pricing__row">
                                        <svg class="pricing__ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7 13H14C16.7614 13 19 10.7614 19 8V8C19 5.23858 16.7614 3 14 3H7V13ZM7 13H5M7 13V21M5 17H13"/>
                                        </svg>
                                        <span class="pricing__row__text">Оборот</span>
                                        <span
                                            v-tippy="__('hints.pricing.turnover')"
                                            class="pricing__ico fm-info"
                                            style="margin-left: auto">
                                        </span>
                                    </div>
                                    <div class="pricing__features">
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-shopping-bag"></span>
                                            <span class="pricing__row__text">Маркетплейсы</span>
                                            <span
                                                v-tippy="__('hints.pricing.marketplaces')"
                                                class="pricing__ico fm-info"
                                                style="margin-left: auto"
                                            ></span>
                                        </div>
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-layout"></span>
                                            <span class="pricing__row__text">Дэшборд</span>
                                        </div>
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-clipboard"></span>
                                            <span class="pricing__row__text">Товары</span>
                                        </div>
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-credit-card"></span>
                                            <span class="pricing__row__text">Расходы</span>
                                        </div>
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-upload"></span>
                                            <span class="pricing__row__text">Импорт себестоимости</span>
                                        </div>
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-rotate-ccw"></span>
                                            <span class="pricing__row__text">Самовыкупы</span>
                                        </div>
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-archive"></span>
                                            <span class="pricing__row__text">Склад</span>
                                        </div>
                                        <!-- <div class="pricing__row">
                                            <span class="pricing__ico fm-upload"></span>
                                            <span class="pricing__row__text">Импорт отчетов</span>
                                            <span
                                                v-tippy="__('hints.pricing.importReports')"
                                                class="pricing__ico fm-info"
                                                style="margin-left: auto"
                                            ></span>
                                        </div> -->
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-users"></span>
                                            <span class="pricing__row__text">Мультиаккаунт</span>
                                            <span
                                                v-tippy="__('hints.pricing.multiAccount')"
                                                class="pricing__ico fm-info"
                                                style="margin-left: auto"
                                            ></span>
                                        </div>
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-trending-up"></span>
                                            <span class="pricing__row__text">Диаграммы</span>
                                            <span
                                                v-tippy="__('hints.pricing.diagrams')"
                                                class="pricing__ico fm-info"
                                                style="margin-left: auto"
                                            ></span>
                                        </div>
                                        <div class="pricing__row">
                                            <span class="pricing__ico fm-message-circle"></span>
                                            <span class="pricing__row__text">Чат поддержки</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="pricing__right">
                                    <div
                                        class="pricing__pack"
                                        v-for="variant in packs"
                                        :key="variant.id"
                                        :class="{'current':isCurrentPack(variant.id)}"
                                    >
                                        <div class="pricing__pack__header">
                                            <div class="pricing__pack__title">{{ variant.title }}</div>
                                            <div v-if="variant.id === 'free' || pricing.period === 'monthly'"
                                                 :style="{marginTop: '31px'}">

                                            </div>
                                            <div v-else-if="pricing.period === 'yearly'"
                                                 class="pricing__pack__price pricing__pack__price_old">
                                                {{ printPrice(variant.price.monthly[selectedCurrency]) }}
                                                <span class="pricing__pack__price__currency">/месяц</span>
                                            </div>
                                            <div class="pricing__pack__price">
                                                {{
                                                    printPrice(variant.price ? variant.price[pricing.period][selectedCurrency] : 0)
                                                }}
                                                <span class="pricing__pack__price__currency">/месяц</span>
                                            </div>
                                            <div class="pricing__pack__payment"
                                                 :style="{visibility: variant.id === 'free' ? 'hidden' : 'visible'}">
                                                <span v-if="variant.id !== 'free' && pricing.period === 'yearly'">{{
                                                        printPrice(variant.price[pricing.period][selectedCurrency] * 12)
                                                    }}</span>
                                                {{ __(`global.pricing.${pricing.period}`) }}
                                            </div>
                                        </div>

                                        <div class="pricing__row">
                                            <span v-if="variant.salesLimit">
                                                {{ variant.salesLimit[selectedCurrency] }}
                                            </span>
                                            <span v-else class="pricing__ico fm-minus"></span>
                                        </div>

                                        <div class="pricing__features">
                                            <div class="pricing__row">
                                                <span>{{ variant.marketplaces }}</span>
                                            </div>
                                            <div
                                                class="pricing__row"
                                                v-for="(active, index) in variant.features"
                                                :key="index"
                                            >
                                                <span v-if="active" class="pricing__ico fm-check"></span>
                                            </div>
                                        </div>

                                        <div class="pricing__pack__actions"
                                             v-if="isCurrentPack(variant.id) && (store.subscription.recurrent || store.subscription.trial)">
                                            <div class="pricing__pack__current">Текущий пакет</div>
                                        </div>

                                        <div
                                            v-if="!isCurrentPack(variant.id) && store.subscription.trial && store.currentPayment !== null && variant.id !== 'free'"
                                            class="pricing__pack__actions"
                                            v-tippy="`Сейчас вы не можете приобрести этот пакет, так как у вас активен бесплатный период и вы уже приобрели пакет &quot;${currentPackTitle}&quot;`"
                                        >
                                            <button class="btn-default btn-regular" disabled>Купить</button>
                                        </div>

                                        <div
                                            v-if="wasTurnoverHit(variant.id)"
                                            class="pricing__pack__actions"
                                            v-tippy="turnoverHitWarningMessage(variant.id)"
                                        >
                                            <button class="btn-default btn-regular" disabled>Купить</button>
                                        </div>

                                        <div class="pricing__pack__actions"
                                             v-if="!wasTurnoverHit(variant.id) && canBuy(variant.id)">
                                            <a class="btn-default btn-regular" href="#"
                                               @click.prevent="buy(variant.id)">{{ buyButtonTitle(variant.id) }}</a>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="pricing-notice" v-if="showPricingNotice">
                                <div class="pricing-notice__inner">
                                    <div class="pricing-notice__body">
                                        <span class="pricing-notice__ico fm-info"></span>
                                        <div class="pricing-notice__content">
                                            <div v-if="store.subscription.trial" class="pricing-notice__label">Дата
                                                окончания бесплатного периода
                                            </div>
                                            <div v-else-if="store.subscription.recurrent" class="pricing-notice__label">
                                                Дата следующего списания
                                            </div>
                                            <div v-else class="pricing-notice__label">Дата окончания оплаченного
                                                периода
                                            </div>
                                            <div class="pricing-notice__value">{{ nextPaymentDate }}</div>
                                        </div>
                                    </div>
                                    <a v-if="store.subscription.recurrent" class="btn-default" href="#"
                                       @click.prevent="showCancelModal = true">Отписаться</a>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <PaymentSuccessModal
            v-if="showSuccessModal"
            @close="showSuccessModal = false"
        />

        <Modal
            v-if="showSuccessModal"
            @close="showSuccessModal = false"
            modalClasses="modal-fullscreen"
        >
            <template #title>
                <h5 class="h4 modal-title">Ваша оплата прошла успешно!</h5>
            </template>
            <template #body>
                <div class="payment-success">
                    <div class="payment-success__pack">
                        <div class="payment-success__pack__ico">
                            <span class="fm-check"></span>
                        </div>
                        <div class="payment-success__pack__body">
                            <div class="payment-success__pack__left">
                                <div class="payment-success__pack__label">Пакет</div>
                                <div class="payment-success__pack__title">{{ currentPackTitle }}</div>
                                <div class="payment-success__pack__label">до {{ nextPaymentDate }}</div>
                            </div>
                            <div class="payment-success__pack__right">
                                <div class="payment-success__pack__title">
                                    <Currency :value="currentPackPrice" :currency="getPaymentCurrency"/>
                                </div>
                                <div class="payment-success__pack__label">{{ currentPackPeriod }}</div>
                            </div>
                        </div>
                    </div>

                    <table class="payment-success__summary">
                        <tbody>
                        <tr>
                            <th scope="row">Способ оплаты</th>
                            <td>Банковская карта</td>
                        </tr>
                        <tr>
                            <th scope="row">Следующее списание</th>
                            <td>{{ nextPaymentDate }}</td>
                        </tr>
                        <tr>
                            <th scope="row">Сумма списания</th>
                            <td>
                                <Currency :value="currentPackPrice" :currency="getPaymentCurrency"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <hr class="payment-success__hr">

                    <p>Вы&nbsp;можете отписаться от&nbsp;автоплатежей в&nbsp;любой момент в&nbsp;разделе &laquo;<a
                        href="/pricing">Настройки/Оплата</a>&raquo;.</p>
                    <p>На&nbsp;вашу электронную почту был отправлен акт об&nbsp;оказании услуг.</p>
                    <p>Благодарим за то, что вы с нами!</p>

                    <img class="payment-success__confetti" src="/img/confetti.svg" alt="">
                </div>
            </template>
            <template #buttons>
                <button class="btn-default btn-regular" @click="showSuccessModal = false">Закрыть</button>
            </template>
        </Modal>

        <Modal
            v-if="showFailModal"
            @close="showFailModal = false"
        >
            <template #title>
                <h5 class="h4 modal-title">К сожалению, оплата не прошла!</h5>
            </template>
            <template #body>
                <p>Попробуйте снова или напишите на support@sellerdata.ru</p>
            </template>
            <template #buttons>
                <button class="btn-default btn-regular" @click="showFailModal = false">Закрыть</button>
            </template>
        </Modal>

        <Modal
            v-if="showCancelModal"
            @close="showCancelModal = false"
        >
            <template #title>
                <h5 class="h4 modal-title">Вы уверены, что хотите отписаться?</h5>
            </template>
            <template #body>
                <p>Отписка начнет действовать с момента, когда закончится оплаченный период использования.</p>
                <p>*Причина отписки:</p>
                <textarea
                    class="input__text-input"
                    type="text"
                    id="cancelReason"
                    v-model="cancelReason"
                    @input="validateField('cancelReason')"
                />

                <!-- Блок для вывода ошибок -->
                <p v-if="this.cancelReason.length==0 || this.cancelReason.length>255" class="input__error">
                    <p v-if="this.cancelReason.length==0">Поле обязательно для заполнения</p>
                    <p v-if="this.cancelReason.length==0">Минимальная длина: 1</p>
                    <p v-if="this.cancelReason.length>255">Максимальная длина: 255</p>
                </p>
            </template>
            <template #buttons>
                <button class="btn-default" @click="showCancelModal = false">Отмена</button>
                <button class="btn-default btn-regular" :disabled="this.cancelReason.length==0 || this.cancelReason.length>255" @click.prevent="cancelSubscription()">Отписаться</button>
            </template>
        </Modal>

        <Modal
            v-if="showCanceledModal"
            @close="showCanceledModal = false"
        >
            <template #title>
                <h5 class="h4 modal-title">Вы успешно отписались!</h5>
            </template>
            <template #body>
                <p>Отписка начнет действовать с момента, когда закончится оплаченный период использования.</p>
            </template>
            <template #buttons>
                <button class="btn-default btn-regular" @click="closeCanceledModal()">Закрыть</button>
            </template>
        </Modal>

        <Modal
            v-if="showEmailNotConfirmedModal"
            @close="showEmailNotConfirmedModal = false"
        >
            <template #title>
                <h5 class="h4 modal-title">Подтвердите ваш email</h5>
            </template>
            <template #body>
                <p>Для осуществления оплаты необходимо подтвердить адрес электронной почты, куда придут документы,
                    подтверждающие оплату и важные уведомления о периоде оплаты. Пожалуйста, нажмите кнопку
                    "Отправить повторно" и пройдите по ссылке в полученном письме.</p>
            </template>
            <template #buttons>
                <button class="btn-default btn-regular" @click="resendEmail" :disabled="resendingEmail">Отправить
                    повторно
                </button>
            </template>
        </Modal>

        <Modal
            v-if="showSubscriptionDurationWarning"
            @close="showSubscriptionDurationWarning = false"
        >
            <template #title>
                <h5 class="h4 modal-title">Вы уверены?</h5>
            </template>
            <template #body>
                Вы собираетесь перейти на пакет "Бизнес" с ежемесячной оплатой,
                но у вас остался еще пакет "{{ currentPackTitle }}" до {{ nextPaymentDate }}.
                В таком случае предыдущая оплата будет пересчитана в соответствии с новым пакетом.
            </template>
            <template #buttons>
                <button class="btn-default" @click="showSubscriptionDurationWarning = false">Отмена</button>
                <button class="btn-default btn-regular" @click.prevent="switchPack()">Подтвердить</button>
            </template>
        </Modal>

        <Modal
            v-if="buyModalShow"
            @close="buyModalShow = false"
            modalId="buyModal"
        >
            <template #title>
                <h5 class="h4 modal-title">Как вы будете платить?</h5>
            </template>
            <template #body>
                <div class="payment-success">
                    <div class="payment-success__pack">
                        <div class="payment-success__pack__body">
                            <div class="payment-success__pack__left">
                                <div class="payment-success__pack__label">Пакет</div>
                                <div class="payment-success__pack__title">{{ selectedPackTitle }}</div>
                            </div>
                            <div class="payment-success__pack__right">
                                <div class="payment-success__pack__title">
                                    <Currency :value="selectedPackPaymentPrice" :currency="getPaymentCurrency"/>
                                </div>
                                <div class="payment-success__pack__label">{{ selectedPackPaymentPeriodShort }}</div>
                            </div>
                        </div>
                    </div>

                    <table class="payment-success__summary">
                        <tbody>
                        <tr>
                            <th scope="row">Периодичность</th>
                            <td>{{ selectedPackPaymentPeriod }}</td>
                        </tr>
                        <template v-if="wasRecalculated">
                            <tr>
                                <th scope="row">Аванс на {{ currentDate }}</th>
                                <td>
                                    <Currency :value="notUsedBalance" :currency="getPaymentCurrency"/>
                                </td>
                            </tr>
                            <tr>
                                <th style="font-weight: bold" scope="row">Остаток к оплате</th>
                                <td style="font-weight: bold">
                                    <Currency :value="toPay" :currency="getPaymentCurrency"/>
                                </td>
                            </tr>
                        </template>
                        <tr>
                            <th scope="row">Следующее списание</th>
                            <td>{{ nextPaymentDateIfPayToday }}</td>
                        </tr>
                        <tr>
                            <th scope="row">Сумма списания</th>
                            <td>
                                <Currency :value="selectedPackPaymentPrice" :currency="getPaymentCurrency"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div id="countrySelect" class="input input_select">
                        <label class="input__label" for="country">Страна</label>
                        <Select2
                            :id="'country'"
                            :options="countryOptions"
                            :settings="countrySelectSettings"
                            v-model="selectedCountry"
                            @select="handleCountryChange($event)"
                        />
                    </div>

                    <div class="notice _warning" v-if="selectedCountry!=='RU' && selectedCountry!=='KZ'">
                        Списание произойдет в {{selectedCurrency=='RUB'?"российских рублях":""}}{{selectedCurrency=='KZT'?"тенге":""}}. Конвертация валюты произойдет по коммерческому курсу
                        вашего банка.
                    </div>

                    <div class="pricing-payment-type">
                        <div class="input" v-show="!canExtend(pack.id)">
                            <div class="input__inner">
                                <input class="input__radio-input" type="radio" id="bank-card" name="payment-type"
                                       v-model="paymentMethod" value="card">
                                <label class="pricing-payment-type__item" for="bank-card">
                                    <img class="pricing-payment-type__ico" src="/img/pricing-card.svg" width="48"
                                         height="48" alt="" loading="lazy">
                                    <span class="pricing-payment-type__text">
                                        Банковская карта
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="input">
                            <div class="input__inner">
                                <input class="input__radio-input" type="radio" id="invoice" name="payment-type"
                                       v-model="paymentMethod" value="invoice">
                                <label class="pricing-payment-type__item" for="invoice">
                                    <img class="pricing-payment-type__ico" src="/img/pricing-invoice.svg" width="48"
                                         height="48" alt="" loading="lazy">
                                    <span class="pricing-payment-type__text">
                                        Счет на оплату
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="pricing-payment-partners" v-if="paymentMethod === 'card'">
                        <svg width="70" height="48" aria-label="Мир">
                            <use href="/img/payment-partners.svg#mir"></use>
                        </svg>

                        <svg width="70" height="48" aria-label="UnionPay">
                            <use href="/img/payment-partners.svg#UnionPay"></use>
                        </svg>

                        <svg width="70" height="48" aria-label="visa">
                            <use href="/img/payment-partners.svg#visa"></use>
                        </svg>

                        <svg width="70" height="48" aria-label="mastercard">
                            <use href="/img/payment-partners.svg#mastercard"></use>
                        </svg>
                    </div>

                    <div class="input">
                        <div class="input__inner">
                            <label class="input__label" for="company">
                                Название фирмы
                                <button
                                    class="input__label__ico fm-info"
                                    v-tippy="'Это наименование фирмы будет отражено в счете на оплату и акте об оказании услуг'"
                                />
                            </label>
                            <input
                                class="input__text-input"
                                type="text"
                                id="company"
                                v-model.trim="companyName"
                                :class="{'invalid': v$.companyName.$error}"
                            />
                        </div>
                    </div>

                    <div class="input">
                        <div class="input__inner">
                            <label class="input__label" for="inn">
                                ИНН
                                <button
                                    class="input__label__ico fm-info"
                                    v-tippy="'Идентификационный номер налогоплательщика юридического лица. Будет отражен в счете на оплату и акте об оказании услуг.'"
                                />
                            </label>
                            <input
                                class="input__text-input"
                                type="text"
                                id="inn"
                                v-model.trim="inn"
                                :class="{'invalid': v$.inn.$error}"
                                @input="validateField('inn')"
                            />
                            <!-- Блок для вывода ошибок -->
                            <p v-if="v$.inn.$error" class="input__error">
                                <div v-if="v$.inn.required.$invalid">Поле обязательно для заполнения</div>
                                <div v-if="v$.inn.numeric.$invalid">Разрешены только цифры</div>
                                <div v-if="v$.inn.minLength.$invalid">Минимальная длина: {{ restrictions.min }}</div>
                                <div v-if="v$.inn.maxLength.$invalid">Максимальная длина: {{ restrictions.max }}</div>
                            </p>
                        </div>
                    </div>

                    <div class="input" v-show="paymentMethod === 'card'">
                        <div class="input__inner">
                            <input class="input__checkbox" type="checkbox" id="subscribtion-terms"
                                   v-model="agreeWithSubscriptionTerms">
                            <label class="input__checkbox-label" for="subscribtion-terms">Я согласен с <a
                                :href="'/subscription-terms?currency='+selectedCurrency" target="_blank">условиями подписки</a></label>
                        </div>
                    </div>

                    <div class="input" style="margin-bottom: 0;">
                        <div class="input__inner">
                            <input class="input__checkbox" type="checkbox" id="personal-data-usage"
                                   v-model="agreeWithPersonalDataUsage">
                            <label class="input__checkbox-label" for="personal-data-usage">Я согласен с <a
                                :href="'/personal-data-usage?currency='+selectedCurrency" target="_blank">условиями обработки персональных данных</a></label>
                        </div>
                    </div>

                    <div v-if="isPaymentDisabled" class="notice _warning" style="margin-top: 20px">
                        Сейчас вы не можете приобрести этот пакет, так как у вас активен бесплатный период
                    </div>
                </div>
            </template>

            <template #buttons>
                <button class="btn-default btn-regular" :disabled="!canPay || v$.inn.$invalid" @click.prevent="forwardToPayment()">Далее
                </button>
            </template>
        </Modal>
    </Layout>
</template>

<script>
import Currency from '@/Components/Common/Currency.vue'
import Layout from '@/Layouts/Authenticated.vue'
import Modal from '@/Components/Common/Modal.vue'
import PaymentSuccessModal from '@/Components/Pricing/PaymentSuccessModal.vue'

import {store} from '@/store'

import {Head} from '@inertiajs/inertia-vue3';
import numeral from 'numeral'
import useVuelidate from '@vuelidate/core'
import {required, numeric, minLength, maxLength} from '@vuelidate/validators'
import Button from '@/Components/Button.vue'
import TableLoader from '@/Components/Common/TableLoader.vue'
import Select2 from 'vue3-select2-component';

export default {
    components: {
        TableLoader,
        Button,
        PaymentSuccessModal,
        Currency,
        Modal,
        Layout,
        Head,
        Select2
    },
    props: {
        terminalKey: String,
        packs: Array,
        isDev: Boolean,
    },
    data() {
        return {
            store,
            accountDataLoaded: false,
            buyModalShow: false,
            periodChecked: false,
            pricing: {
                period: 'yearly',
            },
            amount: 0,
            pack: null,
            showSuccessModal: false,
            showFailModal: false,
            showCancelModal: false,
            showCanceledModal: false,
            showSubscriptionDurationWarning: false,
            showEmailNotConfirmedModal: false,
            agreeWithSubscriptionTerms: false,
            agreeWithPersonalDataUsage: false,
            paymentMethod: 'card',
            companyName: '',
            inn: '',
            paymentProcessing: false,
            resendingEmail: false,

            countrySelectSettings: {
                theme: 'sd_select',
                prompt: 'Страна',
                placeholder: 'Выберите страну',
                allowClear: false,
                width: '100%',
                minimumResultsForSearch: Infinity,
                dropdownParent: '#countrySelect'
            },
            selectedCountry: "RU",
            countries: {
                'RU': 'Россия',
                'BY': 'Беларусь',
                'KZ': 'Казахстан',
                'UZ': 'Узбекистан',
                'AZ': 'Азербайджан',
                'KG': 'Кыргызстан',
                'AM': 'Армения'
            },
            countryInnRestrictions: {
                RU: {min: 10, max: 12},
                BY: {min: 9, max: 9},
                KZ: {min: 12, max: 12},
                UZ: {min: 9, max: 9},
                AZ: {min: 10, max: 10},
                KG: {min: 14, max: 14},
                AM: {min: 8, max: 8},
            },
            selectedCurrency: "RUB",
            currencies: [
                {code: 'RUB', symbol: '₽', name: 'Рубли'},
                {code: 'KZT', symbol: '₸', name: 'Тенге'},
                // Добавить другие валюты по мере необходимости
                // { code: 'EUR', symbol: '€', name: 'Евро' },
            ],
            syncCountryCurrency: {

                'RU': 'RUB',
                'BY': 'RUB',
                'KZ': 'KZT',
                'UZ': 'KZT',
                'AZ': 'KZT',
                'KG': 'KZT',
                'AM': 'KZT'
            },
            syncCurrencyCountry: {
                'RUB': "RU",
                'KZT': "KZ",
            },
            hideSelector: false,
            cancelReason:''
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        const restrictions = this.countryInnRestrictions[this.selectedCountry];

        return {
            companyName: {required},
            inn: {
                required,
                numeric,
                minLength: minLength(this.restrictions.min),
                maxLength: maxLength(this.restrictions.max),
            },
        }
    },
    computed: {
        restrictions(){
            return this.countryInnRestrictions[this.selectedCountry] || { min: 1, max: 20 };
        },
        countryOptions() {
            return Object.entries(this.countries).map(([code, name]) => ({
                id: code,
                text: name,
            })).filter((item) => {
                if (!this.hideSelector) {
                    return true;
                }

                return this.syncCountryCurrency[item.id] === this.selectedCurrency;
            });
        },
        toPay() {
            const selectedPricingPeriodDays = this.pricing.period === 'yearly' ? 365 : 30;
            let toPay = this.selectedPackPaymentPrice;
            if (this.store.currentPayment.left_days > selectedPricingPeriodDays) {
                const x = toPay / selectedPricingPeriodDays;

                toPay = this.store.currentPayment.left_days * x;
            }

            return toPay - this.notUsedBalance;
        },
        notUsedBalance() {
            if (!this.store.currentPayment) {
                return 0;
            }

            return this.store.currentPayment.amount_left;
        },
        currentDate() {
            return moment().format('DD.MM.YYYY');
        },
        paidNotTrial() {
            return this.store.subscription.active && !this.store.subscription.trial && this.store.subscription.type !== 'free';
        },
        samePack() {
            return this.currentPack.id === this.pack.id;
        },
        samePeriod() {
            return this.store.subscription.payment_period === this.pricing.period;
        },
        samePackAndPeriod() {
            return this.samePack && this.samePeriod;
        },
        wasRecalculated() {
            return this.paidNotTrial
                && !this.samePackAndPeriod;
        },
        currentPack() {
            return this.packs.find(pack => pack.id === this.store.subscription.type)
        },
        selectedPackTitle() {
            return this.packs.find(pack => pack.id === this.pack.id).title;
        },
        selectedPackPaymentPrice() {
            const x = this.pricing.period === 'yearly' ? 12 : 1;

            return this.packs.find(pack => pack.id === this.pack.id).price[this.pricing.period][this.selectedCurrency] * x;
        },
        selectedPackPaymentPeriod() {
            return this.pricing.period === 'yearly' ? 'Ежегодная подписка' : 'Ежемесячная подписка';
        },
        selectedPackPaymentPeriodShort() {
            return this.pricing.period === 'yearly' ? 'в год' : 'в месяц';
        },
        nextPaymentDateIfPayToday() {
            const selectedPricingPeriodDays = this.pricing.period === 'yearly' ? 365 : 30;

            if (!this.store.subscription.active) {
                return moment().add(selectedPricingPeriodDays, 'days').format('DD.MM.YYYY');
            }

            if (this.store.subscription.trial) {
                return moment(this.store.subscription.trial_end_date).add(this.pricing.period === 'yearly' ? 365 : 30, 'days').format('DD.MM.YYYY');
            }

            if (this.samePackAndPeriod) {
                return moment(this.store.subscription.next_payment_date).add(selectedPricingPeriodDays, 'days').format('DD.MM.YYYY');
            }

            if (this.samePack && !this.samePeriod) {
                return moment().add(selectedPricingPeriodDays, 'days').format('DD.MM.YYYY');
            }

            if (!this.samePack && this.samePeriod) {
                return moment(this.store.subscription.next_payment_date).format('DD.MM.YYYY');
            }
        },
        currentPackTitle() {
            if (this.store.subscription.type) {
                return this.currentPack.title;
            }

            return '';
        },
        getPaymentCurrency(){
            return this.selectedCurrency;
        },
        currentPackPrice() {
            if (this.store.subscription.type) {
                const x = this.store.subscription.payment_period === 'yearly' ? 12 : 1;
                return this.currentPack.price[this.store.subscription.payment_period][this.selectedCurrency] * x;
            }

            return 0;
        },
        currentPackPeriod() {
            if (this.store.subscription.type) {
                return this.store.subscription.payment_period === 'yearly' ? 'в год' : 'в месяц';
            }

            return '';
        },
        hasNextPaymentDate() {
            return !!this.store.subscription.next_payment_date;
        },
        nextPaymentDate() {
            return this.store.subscription.next_payment_date ? moment(this.store.subscription.next_payment_date).format('DD.MM.YYYY') : 'Никогда';
        },
        trialDateEnd() {
            return moment(this.store.subscription.trial_end_date).format('DD.MM.YYYY');
        },
        showPricingNotice() {
            if (this.store.subscription.trial) {
                return false;
            }

            return this.store.subscription.type !== 'free' && this.hasNextPaymentDate && this.store.subscription.active;
        },
        isPaymentDisabled() {
            return this.store.subscription.trial && this.paymentMethod === 'card' && this.selectedCurrency === 'KZT';
        },
        canPay() {
            if (this.isPaymentDisabled) {
                return false;
            }

            return (
                    (this.paymentMethod === 'card' && this.agreeWithSubscriptionTerms) ||
                    this.paymentMethod === 'invoice'
                )
                && this.agreeWithPersonalDataUsage
                && !this.paymentProcessing;
        }
    },
    watch: {
        'store.subscription.region': function (val) {
            switch (val) {
                case 'kz':
                    this.hideSelector = true;
                    this.selectedCurrency = 'KZT'
                    this.selectedCountry = this.syncCurrencyCountry[this.selectedCurrency]
                    break
                case 'ru':
                    this.hideSelector = true;
                default:
                    this.selectedCurrency = 'RUB';
                    this.selectedCountry = this.syncCurrencyCountry[this.selectedCurrency]
            }
        },
        'store.subscription.company_name': function (val) {
            this.companyName = val;
        },
        'store.subscription.inn': function (val) {
            this.inn = val;
        },
        'store.subscription.payment_period': function (val) {
            if (this.store.subscription.active && this.store.currentPayment !== null && val === 'monthly') {
                this.periodChecked = true;
            }
        },
        'store.subscription.paid_with_card': function (val) {
            if (!val) {
                this.paymentMethod = 'invoice';
            }
        },
        'store.loaded': function (val) {
            this.accountDataLoaded = !!val;
        },
        periodChecked: function (val) {
            this.pricing.period = val ? 'monthly' : 'yearly';
        }
    },
    mounted() {
        const searchParams = new URLSearchParams(window.location.search);
        const status = searchParams.get('sd-status');

        if (status === 'success') {
            this.showSuccessModal = true;
        } else if (status === 'fail') {
            this.showFailModal = true;
        }

        switch (this.store.subscription.region) {
            case 'kz':
                this.hideSelector = true;
                this.selectedCurrency = 'KZT'
                this.selectedCountry = this.syncCurrencyCountry[this.selectedCurrency]
                break
            case 'ru':
                this.hideSelector = true;
            default:
                this.selectedCurrency = 'RUB';
                this.selectedCountry = this.syncCurrencyCountry[this.selectedCurrency]
        }

        if (this.store.subscription.active && this.store.currentPayment !== null && this.store.subscription.payment_period === 'monthly') {
            this.periodChecked = true;
        }

        if (this.store.subscription.paid_with_card) {
            this.paymentMethod = 'card';
        } else {
            this.paymentMethod = 'invoice';
        }
    },
    methods: {
        closeCanceledModal() {
            this.showCanceledModal = false;
            document.location.href = '/pricing';
        },
        canExtend(packId) {
            return this.store.subscription.active
                && !this.store.subscription.trial
                && this.store.subscription.payment_period === this.pricing.period
                && this.store.subscription.type === packId
                && !this.store.subscription.reccurent;
        },
        validateField(fieldName) {
            this.v$[fieldName].$touch(); // Помечаем поле как "посетили"
            this.v$[fieldName].$validate(); // Выполняем валидацию
        },
        handleCurrencyChange() {
            this.selectedCountry = this.syncCurrencyCountry[this.selectedCurrency]
        },
        handleCountryChange(val) {
            this.selectedCurrency = this.syncCountryCurrency[val.id]

            if (this.pack.price === null) {
                this.amount = 0;
            } else  {
                this.amount = this.pack.price[this.pricing.period][this.selectedCurrency] * 100;
            }

            if (this.pricing.period === 'yearly') {
                this.amount = this.amount * 12;
            }
            if(this.inn && this.companyName){
                this.v$.$validate(); // Пересчитываем всю валидацию при смене страны
            }
        },
        buyButtonTitle(packId) {
            if (this.canExtend(packId)) {
                return 'Продлить';
            }

            return 'Купить';
        },
        resendEmail() {
            if (this.resendingEmail) {
                return false;
            }

            this.resendingEmail = true;
            localStorage.setItem('isResendingEmail', moment().unix().toString());

            axios.get(this.route('sendConfirmEmail'))
                .then(() => {
                    this.showEmailNotConfirmedModal = false;
                }).catch(() => {
                this.resendingEmail = false;
            })
        },
        /**
         * @param {String} packId
         * @returns {Boolean}
         */
        wasTurnoverHit(packId) {
            if (packId === 'free') {
                return false;
            }

            const thisPack = this.packs.find(pack => pack.id === packId);

            if (thisPack.salesLimitNumber === null) {
                return false;
            }

            return this.store.subscription.turnover >= thisPack.salesLimitNumber;
        },
        /**
         * @param {String} packId
         * @returns {String}
         */
        turnoverHitWarningMessage(packId) {
            const thisPack = this.packs.find(pack => pack.id === packId);

            return `Сумма оборота вашего магазина уже превышала <span class="nowrap">${thisPack.salesLimit}</span>, поэтому вы не можете перейти на этот пакет`;
        },
        /**
         * @param {String} packId
         * @returns {Boolean}
         */
        isCurrentPack(packId) {
            return this.store.subscription.active
                && this.store.subscription.type === packId
                && (!this.store.subscription.trial || this.store.currentPayment)
                && this.store.subscription.payment_period === this.pricing.period;
        },
        printPrice(price) {
            numeral.locale('cur_' + this.selectedCurrency);

            return numeral(price).format('0,0 $');
        },
        canBuy(packId) {
            if (packId === 'free') {
                return false;
            }

            if (!this.store.subscription.type) {
                return false;
            }

            if (this.store.subscription.trial) {
                return !this.store.currentPayment;
            }

            if (!this.store.subscription.active && new Date(this.store.subscription.next_payment_date)<=new Date()) {
                return true;
            }

            if (this.store.subscription.active && this.store.subscription.type === packId && this.store.subscription.payment_period === 'yearly' && this.pricing.period === 'monthly') {
                return false;
            }

            if (
                this.store.subscription.active
                && this.store.subscription.type === packId
                && (this.store.subscription.payment_period !== this.pricing.period || !this.store.subscription.recurrent)
            ) {
                return true;
            }

            return this.currentPack.canBuy.includes(packId);
        },
        async switchPack() {
            const data = new FormData();
            data.set('amount', this.amount);
            data.set('period', this.pricing.period);
            data.set('pack', this.pack.id);

            await axios.post('/subscription/switch', data)
                .then(response => response.data)
                .then(data => {
                    // reload page
                    document.location.href = `/pricing?sd-status=success`;
                    this.showSubscriptionDurationWarning = false;
                }).catch(error => {
                    this.buyModalShow = false;
                    this.showFailModal = true;
                    console.log(error);
                });
        },
        buy(packId) {

            // if (!this.$page.props.auth.data.emailConfirmed) {
            //     this.showEmailNotConfirmedModal = true;
            //     return;
            // }

            if (this.canExtend(packId)) {
                this.paymentMethod = 'invoice';
            } else {
                this.paymentMethod = this.store.subscription.paid_with_card ? 'card' : 'invoice';
            }

            this.pack = this.packs.find(pack => pack.id === packId);

            this.amount = this.pack.price[this.pricing.period][this.selectedCurrency] * 100;

            if (this.pricing.period === 'yearly') {
                this.amount = this.amount * 12;
            }

            if (this.currentPack.id === 'standard' && this.store.subscription.payment_period === 'yearly' && packId === 'business' && this.pricing.period === 'monthly') {
                this.showSubscriptionDurationWarning = true;
            } else {
                this.buyModalShow = true;
            }
        },
        async cancelSubscription() {
            // const isValid = await this.v$.$validate();
            // if (!isValid) return;
            this.showCancelModal = false;
            const data = {
                'cancelReason':this.cancelReason
            };
            axios.post('/payment/cancel',data).then(() => {
                this.showCanceledModal = true;
            });
        },
        async forwardToPayment() {
            const isValid = await this.v$.$validate();
            if (!isValid) return;

            this.paymentProcessing = true;

            if (this.paymentMethod === 'card' && this.agreeWithSubscriptionTerms && this.agreeWithPersonalDataUsage) {
                await this.payCard();
            }

            if (this.paymentMethod === 'invoice' && this.agreeWithPersonalDataUsage) {
                await this.payInvoice();
            }
        },
        async payInvoice() {
            const data = new FormData();
            data.set('amount', this.amount);
            data.set('period', this.pricing.period);
            data.set('pack', this.pack.id);
            data.set('payment_type', 'invoice');
            data.set('company_name', this.companyName);
            data.set('inn', this.inn);
            data.set('currency', this.selectedCurrency);
            data.set('country', this.selectedCountry);

            await axios.post('/payment/setup', data)
                .then(response => response.data)
                .then(data => {
                    this.buyModalShow = false;
                    window.open(data.pdf, '_blank');
                }).catch(error => {
                    this.buyModalShow = false;
                    this.showFailModal = true;
                    console.log(error);
                }).finally(() => {
                    this.paymentProcessing = false;
                });
        },
        async payCard() {
            const data = new FormData();
            data.set('amount', this.amount);
            data.set('period', this.pricing.period);
            data.set('pack', this.pack.id);
            data.set('payment_type', 'direct');
            data.set('company_name', this.companyName);
            data.set('inn', this.inn);//#TODO change to real data
            data.set('currency', this.selectedCurrency);
            data.set('country', this.selectedCountry);

            await axios.post('/payment/setup', data)
                .then(response => response.data)
                .then(data => {
                    if('redirect_url' in data){
                        window.location.href = data.redirect_url;
                    }else{
                        fetch('https://securepay.tinkoff.ru/v2/Init', {
                            method: 'POST',
                            body: JSON.stringify(data),
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }).then((response) => response.json()).then(data => {
                            if (data.hasOwnProperty('PaymentURL')) {
                                window.location.href = data.PaymentURL;
                            } else {
                                this.buyModalShow = false;
                                this.showFailModal = true;
                            }
                        }).catch(error => {
                            this.buyModalShow = false;
                            this.showFailModal = true;
                            console.log(error);
                        });
                    }
                }).catch(error => {
                    this.buyModalShow = false;
                    this.showFailModal = true;
                    console.log(error);
                }).finally(() => {
                    this.paymentProcessing = false;
                });
        }
    },
}
</script>
<style scoped>
.input__error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
.invalid {
    border-color: red;
}
</style>
