<template>
    <Head title="Admin"/>

    <BreezeAdminLayout>
        <template #header-title>
            <h1 class="page_title">Партнеры"</h1>
        </template>

        <div class="dash__board__container">
            <div class="main_container">

                <div class="filter-panel">
                    <div class="filter-panel__inner">
                        <div class="input input_combo input_combo_2">
                            <div class="input__inner">
                                <input class="input__text-input" id="selectSearchProductRedeems" placeholder="Поиск" type="text" @keyup.enter="filterSubmit(false, null)"
                                       v-model="filter.query">
                                <div class="input__actions">
                                    <button class="input__btn fm-x" type="button" v-if="query" @click="filterReset()" aria-label="Сбросить"></button>
                                    <button class="input__btn fm-search" type="button" @click="filterSubmit(false, null)" aria-label="Искать"></button>
                                </div>
                            </div>
                        </div>

                        <button class="dashboard-nav__btn dashboard-nav__btn_products" :class="{active: (query)}" type="button" data-toggle="modal" data-target="#filterDateModal">
                            <i class="fm-calendar"></i>
                        </button>
                    </div>
                </div>


                <div class="table_outer">
                    <div class="expenses-wrapper">
                        <table v-if="partners"
                               class="table table-fw-widget partners-table table-condensed dashboard-table">
                            <thead>
                            <tr>
                                <th style="text-align: left;" @click="sortBy('name')" class="sortable-header nowrap reorder_outer">
                                    Наименование
                                    <button v-show="filter.sortKey === 'name'" :class="{active: filter.reverse}"
                                            type="button"
                                            class="reorder_button"></button>
                                </th>
                                <th style="text-align: left;" @click="sortBy('code')" class="sortable-header nowrap reorder_outer">
                                    Код
                                    <button v-show="filter.sortKey === 'code'" :class="{active: filter.reverse}"
                                            type="button"
                                            class="reorder_button"></button>
                                </th>
                                <th style="min-width: 70px; text-align: left;" @click="sortBy('registration_quantity')" class="sortable-header nowrap reorder_outer">
                                    Кол-во регистраций
                                    <button v-show="filter.sortKey === 'registration_quantity'" :class="{active: filter.reverse}"
                                            type="button"
                                            class="reorder_button"></button>
                                </th>
                                <th style="min-width: 70px; text-align: left;" @click="sortBy('views')" class="sortable-header nowrap reorder_outer">
                                    Просмотры
                                    <button v-show="filter.sortKey === 'views'" :class="{active: filter.reverse}"
                                            type="button"
                                            class="reorder_button"></button>
                                </th>
                                <th style="min-width: 110px; text-align: left;" @click="sortBy('sum_payments')" class="sortable-header nowrap reorder_outer">
                                    Сумма оплат
                                    <button v-show="filter.sortKey === 'sum_payments'" :class="{active: filter.reverse}"
                                            type="button"
                                            class="reorder_button"></button>
                                </th>
                                <th style="width: 9%; min-width: 80px; text-align: left;" @click="sortBy('percent_of_payments')" class="sortable-header nowrap reorder_outer">
                                    % партнера
                                    <button v-show="filter.sortKey === 'percent_of_payments'" :class="{active: filter.reverse}"
                                            type="button"
                                            class="reorder_button"></button>
                                </th>
                                <th>Выплаты</th>
                                <th>Остаток</th>
                                <th style="width: 1%; text-align: center;"><span class="d-none d-sm-inline">Действия</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(model, key) in sortedPartners" :key="key">
                                <tr class="products-grouped">
                                    <td data-title="Наименование" @click="openUsers(model)">
                                        <div class="item">
                                            <button class="table-accordion__trigger" type="button"
                                                    aria-label="Раскрыть/скрыть"
                                                    :data-partner_id="model.id"
                                                    :aria-selected="model.accordion_opened"></button>
                                            <div class="inner-relative"><span>{{ model.name }}</span></div>
                                        </div>

                                    </td>
                                    <td data-title="Код" @click="openUsers(model)">
                                        <div class="inner-relative"><span>{{ model.code }}</span></div>
                                    </td>
                                    <td data-title="Кол-во регистраций" @click="openUsers(model)">
                                        <div class="inner-relative"><span>{{ model.registration_quantity }}</span></div>
                                    </td>
                                    <td data-title="Уникальные просмотры" @click="openUsers(model)">
                                        <div class="inner-relative"><span>{{ model.views }}</span></div>
                                    </td>

                                    <td data-title="Сумма оплат" @click="openUsers(model)">
                                        <div class="inner-relative"><span> <Currency :value="model.sum_payments"/> </span></div>
                                    </td>
                                    <td data-title="% партнера" @click="openUsers(model)">
                                        <div class="inner-relative"><span> <Currency :value="model.percent_of_payments"/> </span></div>
                                    </td>

                                    <td data-title="Выплаты">
                                        <div class="inner-relative"><span> <input
                                            type="text"
                                            class="border-gray-200 w-24"
                                            step="1"
                                            v-model="model.payout"
                                        /></span> <button class="bg-green-600 cursor-pointer inline-block rounded px-3 py-1 text-white" @click="saveModel(model.payout, model.id)">Сохранить</button></div>
                                    </td>

                                    <td data-title="Остаток" @click="openUsers(model)">
                                        <div class="inner-relative"><span> <Currency :value="model.remaining"/> </span></div>
                                    </td>

                                    <td class="action-td">
                                        <button class="product-more-btn fm-chevron-right" type="button" aria-label="Больше" @click="modelEdit(model)"></button>
                                    </td>
                                </tr>

                                <template v-for="(user, userKey) in model.users">
                                    <tr class="table-accordion__content" :class="{hidden: !model.accordion_opened}">
                                        <td data-title="Наименование" @click="showUserInfo(user)">
                                            <div class="item">
                                                <div class="inner-relative"><span>{{ user.email }}</span></div>
                                            </div>

                                        </td>

                                        <td data-title="Код" @click="showUserInfo(user)">
                                            <div class="inner-relative"><span> - </span></div>
                                        </td>
                                        <td data-title="Кол-во регистраций" @click="showUserInfo(user)">
                                            <div class="inner-relative"><span> - </span></div>
                                        </td>

                                        <td data-title="Уникальные просмотры" @click="showUserInfo(user)">
                                            <div class="inner-relative"><span> - </span></div>
                                        </td>

                                        <td data-title="Сумма оплат" @click="showUserInfo(user)">
                                            <div class="inner-relative"><span> <Currency :value="user.sum_payments"/> </span></div>
                                        </td>
                                        <td data-title="% партнера" @click="showUserInfo(user)">
                                            <div class="inner-relative"><span> <Currency :value="user.percent_of_payments"/> </span></div>
                                        </td>
                                        <td class="action-td">

                                        </td>
                                    </tr>
                                </template>
                            </template>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-if="!partners" class="dashboard_panel dashboard_panel_pt">
                    <div class="dashboard_panel_inner">

                        <div class="expenses-wrapper no_items">
                            <div class="empty_banner">
                                <div class="empty_banner_search">
                                    <span class="fm-rotate-ccw"></span>
                                </div>
                                <h4 class="empty_banner_title">Данные отсутствуют</h4>
                                <button class="btn-default btn-regular" type="button" @click="modelCreate">Добавить</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="buttons-row fixed_bottom_row right_position">
            <a href="javascript:;" class="btn-default btn-regular" @click="modelCreate">Добавить</a>
        </div>

        <FilterDateModal ref="FilterDateModal"
                            v-bind:filter="filter"
                            v-bind:datesAreDefault="false"
                            @apply="filterSubmit" />

        <div class="modal modal-fullscreen modal-fullscreen_filter-modal" id="partnersUserInfoModal" tabindex="-1" aria-labelledby="moreInfoModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="modal-back" type="button" aria-label="Back" data-dismiss="modal">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="modal-title" id="moreInfoModalTitle">Инвойсы</h5>
                        <button type="button" class="modal-close" aria-label="Close" data-dismiss="modal">
                            <i class="fm-close"></i>
                        </button>
                    </div>
                    <div class="modal-body scrollbar">
                        <div class="info-list">
                            <div class="info-list__header">
                                <div class="info-list__header__body">
                                    <div>
                                        <span class="item__product-id">
                                            {{ userInfo.email }}
                                        </span>
                                        <br />
                                        {{ userInfo.date }}
                                    </div>
                                </div>
                            </div>

                            <table class="table table-fw-widget expenses-table">
                                <thead>
                                <tr>
                                    <th>Дата</th>
                                    <th>Сумма</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(payment, key) in userInfo.payments">
                                    <td>{{ payment.date }}</td>
                                    <td><Currency :value="payment.amount"/></td>
                                </tr>
                                </tbody>

                            </table>


                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal modal-fullscreen expenses-create-modal" id="partnerCreate" data-expense-modal aria-labelledby="modal_change_label" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <form class="modal-content" id="addForm" @submit.prevent="submit">
                    <input type="hidden" v-model="form.id">
                    <div class="modal-body scrollbar">
                        <div class="info-list">
                            <div class="info-list__row">
                                <div class="info-list__input">
                                    <div class="info-list__input__inner">
                                        <label class="info-list__input__label" for="xp-1">Наименование</label>
                                        <input class="info-list__input__text-input" id="xp-1" type="text" v-model="form.name" :class="{error: form.errors.name}" placeholder="Название партнера">
                                    </div>
                                    <p class="invalid-feedback">{{ form.errors.name }}</p>
                                </div>

                                <div class="info-list__input">
                                    <div class="info-list__input__inner">
                                        <label class="info-list__input__label" for="partner-code">Код</label>
                                        <input class="info-list__input__text-input" id="partner-code" type="text" v-model="form.code" :class="{error: form.errors.code}" placeholder="Код">
                                    </div>
                                    <p class="invalid-feedback">{{ form.errors.code }}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button v-if="!modelIsCreating" data-dismiss="modal" aria-label="Close" @click="modelDelete(form.id)" class="btn-default" type="button">Удалить</button>
                        <button type="submit" aria-label="Close" class="btn-default"
                                :disabled="!formPopulated"
                                :class="{'btn-regular': formPopulated}">Сохранить
                        </button>
                    </div>



                </form>
            </div>
        </div>


        <div class="modal modal-fullscreen logout-confirm" id="modalDeleteItem" tabindex="-1" aria-labelledby="modal_notificationDelete_label"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="modal-back" type="button" data-dismiss="modal" aria-label="Back">
                            <i class="fm-arrow-left"></i>
                        </button>
                        <h5 class="h4 modal-title">Подтверждение</h5>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                            <span class="fm-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h6 class="logout-confirm__title text-center">Вы уверены?</h6>
                    </div>
                    <div class="modal-footer">
                        <button data-dismiss="modal" aria-label="Close" class="btn-default">Отменить</button>
                        <button aria-label="Close" @click="confirmDelete" class="btn-default btn-regular">Удалить</button>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div id="notificationMessage" v-if="notification.show" class="notification-message is-display-block notification-message_offset">
                <span>{{ notification.message }}</span>
            </div>
        </transition>

        <Modal
            v-if="showModal"
            @close="showModal = false"
        >
            <template #title>
                <h5 class="modal-title">Вы уверенны что хотите сохранить выплату {{this.cachedPayout}} ?</h5>
            </template>
            <template #body>

            </template>
            <template #buttons>
                <button class="btn-default btn-cancel" type="button" @click.prevent="close">Отменить</button>
                <button
                    class="btn-default btn-regular position-relative"
                    @click.prevent="saveSubmit"
                >
                    Активировать
                </button>
            </template>
        </Modal>
    </BreezeAdminLayout>

</template>

<script>
import BreezeAdminLayout from '@/Layouts/Admin.vue'
import useVuelidate from '@vuelidate/core'
import {required, email, helpers, minLength} from '@vuelidate/validators'
import BreezeButton from '@/Components/Button.vue'
import BreezeCheckbox from '@/Components/Checkbox.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeFeedback from '@/Components/Feedback.vue'
import BreezeValidationErrors from '@/Components/AuthErrors.vue'
import {Head, Link} from '@inertiajs/inertia-vue3';
import Currency from "@/Components/Common/Currency.vue";
import ProductsSelector from "@/Components/Products/ProductsSelector.vue";
import {Inertia} from "@inertiajs/inertia";
import {pickBy, throttle} from "lodash-es";
import FilterDateModal from "@/Pages/Admin/Common/FilterDateModal.vue";
import Modal from "@/Components/Common/Modal.vue";

export default {

    components: {
        Modal,
        FilterDateModal,
        ProductsSelector,
        Currency,
        BreezeAdminLayout,
        BreezeButton,
        BreezeCheckbox,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        BreezeFeedback,
        Head,
        Link,
    },

    props: [
        'data',
        'query',
        'dateFrom',
        'dateTo',
        'sortKey',
        'reverse'
    ],

    data() {
        return {
            form: this.$inertia.form({
                "id": null,
                "name": '',
                "code": '',
            }),
            partners: {},
            delete_id: null,
            modelIsCreating: true,
            editingModel: null,
            formPopulated: true,
            showModal: false,
            cachedPayout:0,
            cachedPartnerId:0,
            filter: {
                query: null,
                dateFrom: null,
                dateTo: null,
                sortKey: null,
                reverse: 0,
            },
            canFilter: false,
            notification: {
                show: false,
                message: 'Успешно скопировано'
            },
            userInfo: {},
        }
    },

    mounted() {
        this.init();
        this.partners = this.data;
        this.filter.query = this.query;
        this.filter.dateFrom = this.$props.dateFrom;
        this.filter.dateTo = this.$props.dateTo;
        this.filter.sortKey = this.$props.sortKey;
        this.filter.reverse = this.$props.reverse;
    },

    computed: {
        sortedPartners() {
            if (typeof this.data == 'undefined')
                return false;
            const partners = this.data.slice(); // Создаем копию массива partners
            if (this.filter.sortKey) {
                partners.sort((a, b) => {
                    const modifier = this.filter.reverse ? -1 : 1;
                    const valueA = a[this.filter.sortKey];
                    const valueB = b[this.filter.sortKey];

                    // Проверяем, является ли значение числовым или текстовым и сортируем соответственно
                    if (!isNaN(valueA) && !isNaN(valueB)) {
                        return modifier * (valueA - valueB); // Сравнение чисел
                    } else {
                        return modifier * valueA.localeCompare(valueB); // Сравнение текста
                    }
                });
            }

            return partners;
        }
    },
    watch: {
        form: {
            deep: true,
            handler(newValue, oldValue) {
                if (this.form.price === '' || (this.form.g_number === '' && !this.form.g_numbers.length))
                    this.redeemsFormPopulated = false;
                else
                    this.redeemsFormPopulated = true;
            }
        },
        filter: {
            deep: true,
            handler(newValue, oldValue) {
                localStorage.setItem(this.filterClientDataNS, JSON.stringify(newValue));
                if (newValue.query === '')
                    newValue.query = null;
                if (this.$props.query !== newValue.query || this.$props.dateFrom !== newValue.dateFrom || this.$props.dateTo !== newValue.dateTo){
                    this.canFilter = true;
                }
                else
                    this.canFilter = false;

                this.canFilter = true; // for admin purposes
            }
        }
    },
    methods: {
        init() {
            let self = this;
            $('#partnerCreate').on('hidden.bs.modal', function (e) {
                self.form.errors = {};
            })

            $('#partnersUserInfoModal').on('hidden.bs.modal', function (e) {
                self.userInfo = {};
            })
        },

        filterReset(){
            this.filter.query = '';
            this.filterSubmit(true, null);
        },

        filterSubmit(ignoreCanFilter = false, filter = null, emptyDates = false) {
            if(filter) {
                this.filter.dateFrom = filter.dateFrom;
                this.filter.dateTo = filter.dateTo;
            }
            let self = this;

            if (!this.canFilter && !ignoreCanFilter)
                return false;

            this.$inertia.get(this.route('admin.partners.index'), pickBy({
                query: self.filter.query,
                dateFrom: !emptyDates ? self.filter.dateFrom : null,
                dateTo: !emptyDates ? self.filter.dateTo : null,
                sortKey: self.filter.sortKey,
                reverse: self.filter.reverse,
            }), {
                replace: true,
                preserveState: true,
                onSuccess: () => {
                    self.partners = this.data;
                    self.canFilter = false;
                }
            })
        },
        sortBy(key) {
            if (this.filter.sortKey === key) {
                this.filter.reverse = (this.filter.reverse) ? 0 : 1;

            } else {
                this.filter.sortKey = key;
                this.filter.reverse = 0;
            }
        },
        openUsers(model) {
            model.accordion_opened = !model.accordion_opened;
        },
        showUserInfo(model){
            this.userInfo = model;
            $('#partnersUserInfoModal').modal('show');
        },
        populateEditForm: function (model) {
            this.form.id = model.id;
            this.form.name = model.name;
            this.form.code = model.code;
        },
        modelCreate: function () {
            this.form.reset();
            $('#partnerCreate').modal('show');

            this.modelIsCreating = true;
            this.modelExpense = null;
        },
        modelEdit: function (model) {
            let self = this;
            this.populateEditForm(model);
            this.editingModel = model;
            this.modelIsCreating = false;

            setTimeout(function(){
                $('#partnerCreate').modal('show');

            }, 200)


        },
        modelDelete: function (id) {
            this.delete_id = id;
            $('#modalDeleteItem').modal('show');
        },
        confirmDelete: function () {
            if (!this.delete_id)
                return false;
            let self = this;
            axios.post(this.route('admin.partners.delete'), {id: this.delete_id})
                .then(function (response) {
                    self.delete_id = null;
                    $('#modalDeleteItem').modal('hide');
                    let url = self.route('admin.partners.index') + '?dateFrom=' + self.filter.dateFrom + '&dateTo=' + self.filter.dateTo;
                    if (self.filter.query)
                        url += '&query=' + self.filter.query;
                    if (self.filter.sortKey)
                        url += '&sortKey=' + self.filter.sortKey;
                    if (self.filter.reverse)
                        url += '&reverse=' + self.filter.reverse;

                    Inertia.visit(url, {
                        preserveScroll: true,
                        onSuccess: () => {

                        }
                    })
                })
                .catch(function (error) {
                    self.showNotification(error);
                });
        },

        saveModel(value, partnerId) {
            this.cachedPartnerId = partnerId;
            this.cachedPayout = value;
            this.showModal = true;
        },

        saveSubmit() {
            this.showModal = false;
            let self = this;

            axios.post(this.route('admin.partners.payout',this.cachedPartnerId) , {value: this.cachedPayout})
                .then(function (response) {
                    self.filterSubmit(true, null, true);
                    alert('Успешно сохранено');
                })
                .catch(function (error) {
                    console.log(error);
                })
        },

        close() {
            this.showModal = false;
        },

        submit() {
            let self = this;
            let url = (!this.form.id) ? this.route('admin.partners.add') : this.route('admin.partners.edit')
            let data = this.form.data();
            this.$inertia.post(url, data, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    self.form.reset();
                    $('#partnerCreate').modal('hide');
                    let url = self.route('admin.partners.index') + '?dateFrom=' + self.filter.dateFrom + '&dateTo=' + self.filter.dateTo;
                    if (self.filter.query)
                        url += '&query=' + self.filter.query;
                    if (self.filter.sortKey)
                        url += '&sortKey=' + self.filter.sortKey;
                    if (self.filter.reverse)
                        url += '&reverse=' + self.filter.reverse;

                    Inertia.visit(url, {
                        preserveScroll: true,
                        onSuccess: () => {
                            $('#partnerCreate').modal('hide');
                        }
                    })
                },
                onError: (error) => {
                    console.log(error)
                }
            });
        },
    }
}
</script>
