<template>
    <span class="currency">{{formattedValue}}</span>
</template>

<script>
import numeral from 'numeral'
import { store } from '@/store'

export default {
    name: 'Currency',
    props: {
        value: {
            required: true
        },
        currency:{
            required: false
        }
    },
    data() {
        return {
            store
        }
    },
    computed: {
        formattedValue() {
            if (this.value !== null && !isNaN(this.value)) {
                if (numeral.locales[`cur_${this.getCurrency}`]) {
                    numeral.locale(`cur_${this.getCurrency}`);
                } else {
                    numeral.locale('cur_rub');
                }

                return numeral(this.value).format('0,0.00 $');
            }

            return '';
        },
        getCurrency(){
            return this.currency?this.currency.toLowerCase():this.store.currency.toLowerCase()
        }
    },
}
</script>

<style scoped>

</style>
